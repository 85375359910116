import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Main from '../components/ui/main';
import H1 from '../components/ui/h1';
import H2 from '../components/ui/h2';
import Section from '../components/ui/section';

class WhoPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header />
        <SEO
          description="The Jeffrey Epstein Ranking provides a classification of Universities according to the donations received via the scientific broker Jeffrey Epstein."
          title="Who is Jeffrey Epstein"
        />
        <Main>
          <Section>
            <H1>Who is Jeffrey Einstein </H1>
            <div className="max-w-full mx-auto w-large">
              <H2>Too big to jail</H2>
              <p>Back in 2002, Bill Clinton (as cited in Thomas, 2002: para. 10) described  the financial broker, cultural patron and scientific philanthropist Jeffrey Epstein
                with these words: “Jeffrey is both a highly successful financier and a committed philanthropist with a keen sense of global markets and an in-depth knowledge
                of twenty-first-century science”. Born in Brooklyn in 1953, Epstein died on August 10, 2019 in odd curcumstances in the Federal Prision of Manhattan, where
                he was held since July 6, 2019, charged with sex trafficking dozens of minors (Shallwani et al., 2019). Epstein had already been investigated for similar charges
                from 2005 onwards, although he was granted a a secret plea deal by the former Miami Attorney Alex Acosta (Brown, 2018a), who eventually had to resign as Trump's
                Secretary of Labor due to these disclosures.
              </p>
              <p>As a result, on June 30, 2008 Epstein pleaded guilty to <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=OUylMcXT2pI">procuring an under-age girl for prostitution</a> and was sentenced to 18 months incarceration in the private
                wing of the Palm Beach County stockade (Brown, 2018b), as well as 12 months of community services at the Florida Science Foundation that he founded during the
                plea negotiations in November 2007 (Brezel, 2019).  This allowed Epstein to enjoy long work releases at his West Palm Beach office (Brown, 2018b), where he
                allegedly kept receiving women from his sex trafficking network (Katersky & Hill, 2019),  before his early release on probation after serving 13 months.
              </p>
              <p>It is Epstein's legal Dream Team who should be credited with this plea deal, with a special mention to Alan Dershowitz and Ken Starr: The former, a distinguished
                Law Professor ,  involved his Harvard colleague Steven Pinker yto assist him in Epstein’s defense (Aldhous, 2019a); the latter,  was Dean of the Pepperdine
                University School of Law and then President of the Baylon University until he was forced out over accusation of covering up sexual assaults in the campus
                (Weston Phippen, 2016), all of which did not prevent him from rejoining Epstein’s legal team in 2019 (Starr et al., 2019). As I write these lines, Dershowitz
                and Starr are back at the informative frontline as part of the legal team that helped overcome the  impeachment of Donald Trump, who chose these visionary words
                to describe Epstein: “He’s a lot of fun to be with. It is even said that he likes beautiful women as much as I do, and many of them are on the younger side”
                (Thomas, 2002: para. 8).
              </p>
              <p>
                Next: <Link to="/what-is-jer-pecunia-non-olet">What is JER™: Pecunia non olet</Link>
              </p>
            </div>
          </Section>
        </Main>
      </Layout >
    )
  }
}

export default WhoPage
